import { get, post, put } from "./config";

export const practiceResultGetTime = async data => {
    const url = '/app/practiceResult/getTime?times='+data.times+'&practiceResultId='+data.practiceResultId;
    return await get(url,data,true);
};
export const getPracticeList = async ({page,size}) => {
    const url = `/app/practice/self/list?page=${page}&size=${size}`;
    const { practices } = await get(url,true);
    return practices;
};

export const getPracticeEveryday = async () => {
    const url = '/app/practice/everyday/list';
    return await get(url,true);
};

export const startPractice = async practiceId => {
    const url = '/app/practice-result';
    const data = {
        practiceId
    };
    return await post(url,data,true);
};

export const continuePractice = async practiceResultId => {
    const url = '/app/practice-result/self/continue';
    const data = {
        practiceResultId
    };
    return await put(url,data,true);
};

export const finishPracticeEveryday = async data => {
    const url = '/app/practice-result/everyday';
    return await put(url,data,true);
};

export const finishPracticeSelf = async data => {
    const url = '/app/practice-result/self';
    return await put(url,data,true);
};

export const getPracticeResult = async practiceResultId => {
    const url = `/app/practice-result?practiceResultId=${practiceResultId}`;
    return await get(url,true);
};

export const getPracticeInfo = async practiceId => {
    const url = `/app/practice/self?practiceId=${practiceId}`;
    return await get(url,true);
};